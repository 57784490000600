import $ from 'jquery';

document.addEventListener('DOMContentLoaded', () => {
  Sceon.Stories = {};
  Sceon.Stories.url = '';
  Sceon.Stories.container = '#and6-stories';

  Sceon.Stories.CallFromMobile = (accountID, profileID) => {
    $.ajax({
      url: '/callfrom',
      method: 'POST',
      data: {
        profile_id: profileID,
        account_id: accountID,
      },
    });
  };

  Sceon.Stories.CallFromWhatsapp = function (accountID, profileID) {
    $.ajax({
      url: '/callfromwhatsapp',
      method: 'POST',
      data: {
        profile_id: profileID,
        account_id: accountID,
      },
    });
  };

  const onStoryItemSeen = (event) => {
    $.ajax({
      method: 'POST',
      url: '/story/seen',
      data: { item_id: event.id },
    });
  };

  const onLike = (event) => {
    $.ajax({
      method: 'POST',
      url: '/story/like',
      data: { item_id: event.id },
    });
  };

  const onUnlike = (event) => {
    $.ajax({
      method: 'POST',
      url: '/story/unlike',
      data: { item_id: event.id },
    });
  };

  const onNavigateProfile = (event) => {
    window.location.href = event.profileUrl;
  };

  function generateStoryTitle(story) {
    return `
      <div class="story-item-title">
          <a href="/sex-model/${story.name}-${story.id}">${story.name}</a>
          <span>${story.lastUpdatedAgo}</span>
      </div>
    `;
  }

  function generateStoryFooter(story) {
    const phoneHTML = !story.phoneNumber
      ? ''
      : `
        <a href="tel:+${story.phoneNumber}" onclick='Sceon.Stories.CallFromMobile(${story.accountID},"${story.profileID}")'>
          <i class="sc-and6 sc-and6-phone"></i>
          <span>${translations.lbl_call}</span>
        </a>
    `;

    const wappHTML = !story.hasWhatsapp
      ? ''
      : `
        <a href="https://wa.me/+${story.phoneNumber}" target="_blank" onclick="Sceon.Stories.CallFromWhatsapp(${story.accountID},'${story.profileID}')">
          <i class="sc-and6 sc-and6-whatsapp"></i>
          <span>${translations.lbl_whatsapp}</span>
        </a>
    `;

    return `
      <div class="story-item-actions">
          ${phoneHTML}
           <a href="/sex-model/${story.name}-${story.id}">
             <i class="sc-and6 sc-and6-profile"></i>
             <span>${translations.lbl_go_to_profile}</span>
           </a>
          ${wappHTML}
      </div>
    `;
  }

  function generateContestContent(story) {
    return ``;
  }

  Sceon.Stories.init = function (data) {
    $.ajax({
      method: 'GET',
      url: `/story?tmp=${Date.now()}`,
      data: data,
      success: function (resp) {
        if (resp.length) {
          const renderStory = new SceonStory({
            storyList: resp.map((story) => ({
              ...story,
              titleNestedHtml: generateStoryTitle(story),
              items: story.items.map((item) => ({
                ...item,
                footerNestedHtml: generateContestContent(item),
                footerNestedActionHtml: generateStoryFooter(story),
                caption: item.description,
              })),
            })),
            storyWrapper: document.getElementById('stories'),
            styleConfigs: {
              sc_is_custom_scrollbar: true,
              sc_scrollbar_distance: '16px',
            },
            configs: {
              seenByStoryItemOpen: true,
            },
            events: {
              onLike,
              onUnlike,
              onStoryItemSeen,
              onNavigateProfile,
            },
          });

          $(Sceon.Stories.container)?.removeClass('hidden');

          if ($('.profile-bio-container').length) {
            $('.profile-bio-container').addClass('has-story');
          }
        }
      },
    });
  };
});
